import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import Changelog from '~/gatsby-theme-docz/components/Changelog';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Changelog mdxType="Changelog" />
    <h2 {...{
      "id": "faq"
    }}>{`FAQ`}</h2>
    <h3 {...{
      "id": "hva-betyr-version-bump-only-for-package-enturxxxxx"
    }}>{`Hva betyr "Version bump only for package @entur/xxxxx"?`}</h3>
    <p>{`Her har pakken en annen pakke fra designsystemet som dependency, og dermed bumpes denne for å oppdatere til den nyeste versjonen av den oppdaterte avhengigheten.`}</p>
    <h3 {...{
      "id": "jeg-forstår-ikke-changelogenønsker-en-oppsummering-av-en-release"
    }}>{`Jeg forstår ikke changelogen/ønsker en oppsummering av en release`}</h3>
    <p>{`Sjekk ut kanalen #releases-designsystem på Slack. Der vil det postes oppsummeringer av hver release i et mer leselig format enn de korte commit-meldingene i changelogen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      